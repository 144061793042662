body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ExtraBold.bdf3ea41.eot);
    src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
        url(/static/media/Gilroy-ExtraBold.bdf3ea41.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ExtraBold.2037b7c0.woff) format('woff'),
        url(/static/media/Gilroy-ExtraBold.07e6a6cd.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Heavy.335c7ebf.eot);
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(/static/media/Gilroy-Heavy.335c7ebf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Heavy.ffa5800e.woff) format('woff'),
        url(/static/media/Gilroy-Heavy.8d36efeb.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-LightItalic.b276abdd.eot);
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url(/static/media/Gilroy-LightItalic.b276abdd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-LightItalic.0cf6584c.woff) format('woff'),
        url(/static/media/Gilroy-LightItalic.a774850a.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ThinItalic.0af99171.eot);
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url(/static/media/Gilroy-ThinItalic.0af99171.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ThinItalic.72bca972.woff) format('woff'),
        url(/static/media/Gilroy-ThinItalic.9575e892.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BlackItalic.df2b7fe0.eot);
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url(/static/media/Gilroy-BlackItalic.df2b7fe0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BlackItalic.7fc70f6c.woff) format('woff'),
        url(/static/media/Gilroy-BlackItalic.25a0c193.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-BoldItalic.ebdd3100.eot);
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url(/static/media/Gilroy-BoldItalic.ebdd3100.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-BoldItalic.6171e2e9.woff) format('woff'),
        url(/static/media/Gilroy-BoldItalic.2b56aed0.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-SemiBold.2b2e536e.eot);
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url(/static/media/Gilroy-SemiBold.2b2e536e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-SemiBold.989947b0.woff) format('woff'),
        url(/static/media/Gilroy-SemiBold.05bdf30b.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLightItalic.0bf003f5.eot);
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url(/static/media/Gilroy-UltraLightItalic.0bf003f5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLightItalic.f07d7e71.woff) format('woff'),
        url(/static/media/Gilroy-UltraLightItalic.21f2f1d8.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-SemiBoldItalic.eebe073a.eot);
    src: local('Gilroy SemiBold Italic'), local('Gilroy-SemiBoldItalic'),
        url(/static/media/Gilroy-SemiBoldItalic.eebe073a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-SemiBoldItalic.2706d075.woff) format('woff'),
        url(/static/media/Gilroy-SemiBoldItalic.d9652658.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Light.c2a5327d.eot);
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url(/static/media/Gilroy-Light.c2a5327d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Light.f9b62dd8.woff) format('woff'),
        url(/static/media/Gilroy-Light.73bfa12b.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-MediumItalic.27cc5330.eot);
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url(/static/media/Gilroy-MediumItalic.27cc5330.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-MediumItalic.f8ac8bc5.woff) format('woff'),
        url(/static/media/Gilroy-MediumItalic.9bc60a85.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ExtraBoldItalic.04a0adfb.eot);
    src: local('Gilroy ExtraBold Italic'), local('Gilroy-ExtraBoldItalic'),
        url(/static/media/Gilroy-ExtraBoldItalic.04a0adfb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-ExtraBoldItalic.e43cca6c.woff) format('woff'),
        url(/static/media/Gilroy-ExtraBoldItalic.1ccd0d2f.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Regular.59525ce1.eot);
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url(/static/media/Gilroy-Regular.59525ce1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Regular.8e8705d0.woff) format('woff'),
        url(/static/media/Gilroy-Regular.ae5e7255.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-HeavyItalic.de665040.eot);
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(/static/media/Gilroy-HeavyItalic.de665040.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-HeavyItalic.c9a8c7bb.woff) format('woff'),
        url(/static/media/Gilroy-HeavyItalic.574db652.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Medium.ce12e7f2.eot);
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(/static/media/Gilroy-Medium.ce12e7f2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Medium.7d5dc179.woff) format('woff'),
        url(/static/media/Gilroy-Medium.6444f14a.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-RegularItalic.d2d1a69f.eot);
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url(/static/media/Gilroy-RegularItalic.d2d1a69f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-RegularItalic.5b729efb.woff) format('woff'),
        url(/static/media/Gilroy-RegularItalic.4b9bfe4d.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-UltraLight.1b3e628e.eot);
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url(/static/media/Gilroy-UltraLight.1b3e628e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-UltraLight.22b0d1f5.woff) format('woff'),
        url(/static/media/Gilroy-UltraLight.d3d30383.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Bold.5e111ca0.eot);
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(/static/media/Gilroy-Bold.5e111ca0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Bold.32750b00.woff) format('woff'),
        url(/static/media/Gilroy-Bold.3cf0ee27.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Thin.936acdcb.eot);
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url(/static/media/Gilroy-Thin.936acdcb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Thin.e1358f50.woff) format('woff'),
        url(/static/media/Gilroy-Thin.b4e27db9.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Black.3ee088eb.eot);
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(/static/media/Gilroy-Black.3ee088eb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Gilroy-Black.bde8bcef.woff) format('woff'),
        url(/static/media/Gilroy-Black.d59719bc.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Web Serveroff';
    src: url(/static/media/WebServeroff.867d506f.ttf);
    font-weight: 400;
    font-style: normal;
}
* {
    margin: 0;
    padding: 0;
}

ul, li {
    list-style: none;
}


/*-----------------------------*/
/*-----------mainPage--------*/
/*-----------------------------*/
.mainPage {
    background: url(/static/media/background.1a55035f.jpg) no-repeat;
    background-attachment: fixed;
    background-color: #000;
    background-position: 50%;
    background-size: cover;
    height: 100vh
}

@media (max-width: 720px) {
    .mainPage {
        background:url(/static/media/background.1a55035f.jpg) no-repeat;
        background-attachment: fixed;
        background-position: 50%;
        background-size: cover
    }
}
/*-----------------------------*/
/*-----------------------------*/
/*-----------------------------*/


/*------------------------------------------------------------------------------*/


/*-----------------------------*/
/*-----------barbersPage--------*/
/*-----------------------------*/
.barbersPage {
    background: url(/static/media/background.1a55035f.jpg) no-repeat;
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

}

@media (max-width: 720px) {
    .barbersPage {
        background: url(/static/media/720_mob_bg.f2d07e1b.png) no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
    }
}
/*-----------------------------*/
/*-----------------------------*/
/*-----------------------------*/

/*------------------------------------------------------------------------------*/


/*-----------------------------*/
/*-----------servicesPage--------*/
/*-----------------------------*/
.servicesPage {
    background: url(/static/media/service_bg.7ba9cdc1.jpg) no-repeat;
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

}
/*-----------------------------*/
/*-----------------------------*/
/*-----------------------------*/


/*------------------------------------------------------------------------------*/


/*-----------------------------*/
/*-----------worksPage--------*/
/*-----------------------------*/
.worksPage {
    /*background: url("../img/background.jpg") no-repeat;*/
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

}

@media (max-width: 720px) {
    .worksPage {
        /*background: url("../img/720_mob_bg.png") no-repeat;*/
        background-color: #000;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
    }
}
/*-----------------------------*/
/*-----------------------------*/
/*-----------------------------*/
a {
    text-decoration: none;
}

body {
    font-family: "Gilroy", serif;
}

h1, h2 {
    color: #fff;
}


.container {
    max-width: 1750px;
    margin: 0 auto;
    padding: 0 5%;
}


.header__wrapper {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1270px) {
    .header__wrapper {
        padding-top: 70px;
    }
}

nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__link {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #fff;
}


.work__time {
    color: #b9b9b9;
    font-weight: 400;
    text-transform: inherit;
}

.header__button {
    border: 2px solid #ffffff;
    border-radius: 50px;
    padding: 12px 10px 12px 25px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}


.header__button:hover {
    background: #fff;
    color: #000;
    transition: 0.7s all;
}

.header__button i {
    font-size: 20px;
    margin-left: 10px;
}

.nav__left {
    height: 100%;
}

.nav__left li {
    position: relative;
}

.nav__left .nav__link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5%;
    left: 0;
    background-color: #fff;
    visibility: visible;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.nav__left .nav__link:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.nav__left li, .nav__right li {
    margin: 0 25px;
}

.menu__icon__open, .menu__icon__close {
    display: none;
    color: #fff;
    font-size: 30px;
    align-self: center;
    z-index: 100;
}


.main__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: calc(100vh - 146px - 129px);
}

.main__wrapper .col2 {
    width: 446px;
    height: 446px;
    border: 8px solid #ffffff;
    /* border-radius: 50%; */
    text-align: center;
    line-height: 446px;
    margin: 0 55px;
}

.main__wrapper h1 {
    font-family: 'Web Serveroff', serif;
    font-size: 200px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}

.main__wrapper h2, .main__wrapper h3 {
    font-family: 'Web Serveroff';
    font-size: 50.5px;
    line-height: 1.2;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
}

.main__wrapper .col1 {
    text-align: right;
}

footer {
    /* padding-top: 70px; */
    padding-bottom: 10px;
    width: 100%;
    bottom: 0;


}

/*@media (max-width: 1112px) {*/
/*    footer {*/
/*        position: fixed;*/
/*    }*/
/*}*/

footer ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.footer__address a {
    font-family: 'Gilroy', serif;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #ffffff;
}

.addresses{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.footer__address {
    display: flex;
    align-items: center;
    margin-top: 8px;
    
}
.footer__address img{
    width: 20px;
}

 .footer__address img {
    margin-right: 20px;
} 
.addresses{
    display: flex;
    flex-direction: column;
}

.footer__instagram {
    margin-left: auto;
    /* margin: 0 1px; */
}

.footer__partner {
    margin-left: auto;
    /* margin: 0 1px; */
}

.footer__youtube{
    margin: 0 5px;
}

.main__services {
    color: #fff;
    font-size: 24px;
    font-family: "Gilroy", serif;
}

.service {
    display: flex;
    flex-direction: column;
    padding: 50px 25px;

}

@media (max-width: 400px){
    .footer__address img{
        width: 15px;
    }
}

/*------------------------------------*/
/*-------------Menu button------------*/
/*------------------------------------*/
.list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.dropdown {
    display: none;
}

/*------------------------------------*/
/*------------------------------------*/
/*------------------------------------*/


.service hr{
    margin-top: 20px;
}

.service__price {
    margin: 25px 0;
}

.service_wrapper {
    margin-top: 100px;
}

.barbers__wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.barber {
    position: relative;
    width: 25%;
    filter: grayscale(100%);
}

.barber:hover {
    filter: none;
    transition: 0.3s all;
}

.barber:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.photo{
    height: 100%;
}
.barber img {
    width: 100%;
    height: 100%;
}

.description {
    color: #fff;
    position: absolute;
    bottom: 3%;
    left: 3%;
    line-height: 1.5;
}

.description p {
    display: none;
    transition: 0.3s all;
}

.description p.active {
    display: block;
    transition: 0.3s all;
}

.description .name {
    font-size: 48px;
    font-family: "Gilroy", serif;
}

.description p {
    margin-top: 10px;
    font-size: 20px;
}

.works__wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.work {
    width: 33%;
}

.work img {
    width: 100%;
    height: 100%;
    /*height: 550px;*/
}
.fa-phone-flip{
    color: #fff;
}
.header__phone__icon{
    display:  none;
}
.header__phone__icon svg{
    color: #fff;
    font-size: 24px;
    margin-left: 30px;
}

@media (max-width: 933px) {
    .work {
        width: 50%;
    }
}

@media (max-width: 1470px) {
    .nav__link {
        font-size: 15px;
    }

    .nav__left li, .nav__right li {
        margin: 0 17px;
    }

}

@media (max-width: 1366px) {
    .description p {
        display: block;
        transition: 0.3s all;
    }
}

@media (max-width: 1220px) {
    .work__time {
        display: none;
    }
}

@media (max-width: 1112px) {

    .nav__right li:not(:last-child) {
        margin: 20px 0;
    }


    .main__wrapper {
        flex-direction: column;
    }

    .main__wrapper img {
        display: none;
    }

    .main__wrapper div {
        margin: 20px 0;
    }

    .nav__left li{
        margin-top: 40px;
    }
    .header__left__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(-100%, 0);
        background: #000;
        transition: all .5s ease 0s;
        /*padding: 100px 30px 30px 30px;*/
        overflow: auto;
        display: block;

    }
    h2{
        display: none;
    }

    .header__left__wrapper.active {
        transform: translate(0, 0);
        transition: all .5s ease 0s;
        z-index: 100;
    }


    .nav__left {
        flex-direction: column;
    }

    .header__left__wrapper .nav__link {
        font-size: 30px;
        margin: 30px 0;
    }


    .nav__right {
        flex-direction: row;
    }

    .menu__icon__open {
        display: block;
    }


    .nav__right li:last-child {
        margin: 0;
    }

    .header__phone {
        margin-left: 25px;
    }

    .main__wrapper {
        height: calc(74vh - 100px);
    }

    .header__wrapper {
        padding-top: 30px;
    }

    .service_wrapper {
        margin-top: 50px;
    }

    footer {
        padding-bottom: 10px;
    }

}


@media screen and (max-width: 869px), screen and (max-height: 760px) {
    .main__wrapper h1 {
        font-size: 150px;
    }

    .main__wrapper .col2 {
        width: 350px;
        height: 350px;
        /*border: 8px solid #ffffff;*/
        /*border-radius: 50%;*/
        text-align: center;
        line-height: 350px;
        margin: 0 55px;
    }
}


@media (max-width: 700px) {
    footer img {
        width: 25px;
    }

    .footer__partner img {
        width: 55px;
    }
    .footer__partner {
        width: auto;
    }
}

@media (max-width: 480px) {
    .container {
        padding-left: 3%;
        padding-right: 3%;
    }

    .header__phone {
        display: none;
    }
    .header__phone__icon{
        display: block;
    }

    .main__wrapper h1 {
        font-size: 150px;
    }


    .main__wrapper .col2 {
        height: 300px;
        line-height: 300px;
        margin: 0 55px;
        text-align: center;
        width: 300px
    }
}

@media (max-width: 1481px) {
    .description p {
        font-size: 16px;
    }
}

@media (max-width: 1189px) {
    .barber {
        width: 30%;
    }
}

@media (max-width: 933px) {
    .barber {
        width: 50%;
    }

    .description p {
        font-size: 20px;
    }
}

@media (max-width: 650px) {
    .description p {
        font-size: 16px;
    }
}

@media (max-width: 550px) {
    .barber {
        width: 100%;
    }

    .description p {
        font-size: 20px;
    }
}


